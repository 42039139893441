<template>
  <div>
    <div v-show="!isDataLoaded">...</div>
    <div v-show="isDataLoaded" class="wrapper">
      <div style="position: sticky; top: 0; z-index: 10; background: #f0f0f0">
        <CutAudioDuration
          :duration="audioDuration"
          :playingTimeCur.sync="playingTimeCur"
          :uLine.sync="selectedULine"
          :isPlaying="isPlayingAudio"
          :sysLines="sysAudioLines"
          :audioFileName="audioFileName"
          :contentType="contentType"
          @insertRLineAfter="insertRLineNext(selectedULine.si, $event)"
          @clickPlay="playAudio($event)"
          @hasOperate="pushOpState()"
          @clickPause="pauseAudio"
        />
        <div style="padding-bottom: 1em">
          <Button
            style="margin-left: 3.5em"
            @click="unionRLinesOfSelected"
            :disabled="selectedRLen < 2"
            >合并</Button
          >
          <Button :disabled="!selectedRLen" @click="matchRLinesOfSelected"
            >匹配</Button
          >
          <Poptip
            confirm
            title="确定删除所选配音吗？"
            @on-ok="deleteRLinesOfSelected"
          >
            <Button
              style="margin-left: 1.5em"
              type="error"
              :disabled="!selectedRLen"
              >删除</Button
            >
          </Poptip>

          <Button
            style="float: right; margin-right: 2em"
            @click="clearRSelect"
            :disabled="!selectedRLen"
            >清空选择</Button
          >
        </div>
        <Button @click="popAndUseOpState()">撤销</Button>
        <Poptip confirm title="确定清理缓存吗？" @on-ok="clearOps()">
          <Button>清理缓存</Button>
        </Poptip>
      </div>
      <BackgroundMusic :musicInfo="info" />
      <SysManageItem
        v-for="uLine in unionLines"
        :key="uLine.id"
        :uLine="uLine"
        :lines="audioLines"
        :sysLines="sysAudioLines"
        :playingTimeCur="isPlayingAudio ? playingTimeCur : -1"
        :activeRLine="selectedULine && sysAudioLines[selectedULine.si]"
        @clickPlay="playAudio(sysAudioLines[uLine.si].audioDur)"
        @clickPause="pauseAudio"
        @selectRLine="selectedULine = uLine"
        @regenerateUlines="generateUlines()"
        @hasOperate="pushOpState()"
        @select="onSelectR($event)"
      />
    </div>
  </div>
</template>

<script>
import SysManageItem from "./components/SysManageItem";
import CutAudioDuration from "./components/CutAudioDuration";
import {
  autoMatch,
  setUnion,
  generateUnionLines,
} from "./components/sysAudioService";

import CacheService from "./opCacheService";
console.log("设置showALog为true，可以开启配音性能日志的打印！");
export default {
  components: { SysManageItem, CutAudioDuration },
  props: {
    audioLines: {
      required: true,
      type: Array,
    },
    sysAudioLines: {
      required: true,
      type: Array,
    },
    audioUrl: {
      type: String,
    },
    audioFileName: {
      type: String,
    },
    audioKey: {},
    contentType: {},
    unionLines: {
      type: Array,
      required: true,
    },
    bizId: {},
    info: {},
  },
  data() {
    return {
      playingTimeCur: -1,
      selectedULine: null,
      audioDuration: -1,
      isDataLoaded: false,
      isPlayingAudio: false,
      audioPlayer: null,
      firstRSelectInd: -1,
      cacheService: null,
    };
  },
  computed: {
    selectedRLen() {
      return this.sysAudioLines.filter((sysLine) => sysLine.isSelected).length;
    },
  },
  methods: {
    handlePlaying(max) {
      this.playingTimeCur = Math.floor(this.audioPlayer.currentTime * 1000);

      if (this.playingTimeCur >= max) {
        this.pauseAudio();
      } else if (this.isPlayingAudio) {
        requestAnimationFrame(() => {
          this.handlePlaying(max);
        });
      }
    },
    playAudio(dur) {
      this.audioPlayer.currentTime = dur[0] / 1000;
      this.audioPlayer.play();
      this.isPlayingAudio = true;
      this.playingTimeCur = dur[0];
      requestAnimationFrame(() => {
        this.handlePlaying(dur[1] - 20);
      });
    },
    pauseAudio() {
      this.audioPlayer.pause();
      this.isPlayingAudio = false;
    },
    insertRLineNext(si, newLine) {
      this.sysAudioLines.splice(si + 1, 0, newLine);
      this.generateUlines();
    },
    // *************************************************************************以下是多选操作：*************************************************
    clearRSelect() {
      this.sysAudioLines.forEach((sl) => {
        sl.isSelected = false;
      });
      this.firstRSelectInd = -1;
    },
    onSelectR(si) {
      if (this.firstRSelectInd === -1) {
        this.clearRSelect();
        this.firstRSelectInd = si;
        this.sysAudioLines[si].isSelected = true;
        return;
      }
      // 最后判断是否取消了起点：
      if (this.firstRSelectInd === si) {
        this.clearRSelect();
        return;
      }

      this.$nextTick(() => {
        // 之外的都为false，之内的都为true
        let min = Math.min(si, this.firstRSelectInd);
        let max = Math.max(si, this.firstRSelectInd);
        for (let i = 0; i < min; i++) {
          this.sysAudioLines[i].isSelected = false;
        }
        for (let i = max + 1; i < this.sysAudioLines.length; i++) {
          this.sysAudioLines[i].isSelected = false;
        }
        for (let i = min; i <= max; i++) {
          this.sysAudioLines[i].isSelected = true;
        }
      });
    },
    // 合并选择的音频：
    unionRLinesOfSelected() {
      this.unionLines.forEach(({ i, si }) => {
        let sysLine = this.sysAudioLines[si];
        if (sysLine && sysLine.isSelected && sysLine.matched) {
          setUnion(this.audioLines[i], sysLine, false);
        }
      });
      let firstSysLine = null;
      let selectedSi = this.selectedULine && this.selectedULine.si;
      for (let i = 0; i < this.sysAudioLines.length; i++) {
        if (!this.sysAudioLines[i].isSelected) {
          if (firstSysLine) {
            break;
          }
          continue;
        }
        let audioDur = this.sysAudioLines[i].audioDur;
        if (audioDur[0] === 0 && audioDur[1] === 0) {
          this.sysAudioLines.splice(i, 1);
          i--;
          continue;
        }
        if (!firstSysLine) {
          firstSysLine = this.sysAudioLines[i];
        } else {
          if (selectedSi === i) {
            // 如果是选中的正在编辑的音频，要清理掉
            this.selectedULine = null;
          }
          firstSysLine.text += this.sysAudioLines[i].text;
          firstSysLine.audioDur = [firstSysLine.audioDur[0], audioDur[1]];
          this.sysAudioLines.splice(i, 1);
          i--;
        }
      }
      this.generateUlines();
      this.clearRSelect();
    },

    // 删除选择的配音
    deleteRLinesOfSelected() {
      this.unionLines.forEach(({ i, si }) => {
        let sysLine = this.sysAudioLines[si];
        if (sysLine && sysLine.isSelected && sysLine.matched) {
          setUnion(this.audioLines[i], sysLine, false);
        }
      });
      let selectedSi = this.selectedULine && this.selectedULine.si;
      for (let i = 0; i < this.sysAudioLines.length; i++) {
        if (this.sysAudioLines[i].isSelected) {
          this.sysAudioLines.splice(i, 1);
          if (selectedSi === i) {
            // 如果是选中的正在编辑的音频，要清理掉
            this.selectedULine = null;
          }
          i--;
        }
      }
      this.generateUlines();
      this.clearRSelect();
    },
    matchRLinesOfSelected() {
      for (let ul of this.unionLines) {
        let sysLine = this.sysAudioLines[ul.si];
        let line = this.audioLines[ul.i];
        if (sysLine && sysLine.isSelected && line) {
          setUnion(line, sysLine, true);
        }
      }
      this.clearRSelect();
      this.pushOpState();
    },
    generateUlines() {
      if (window.showALog) {
        console.log("%c\n~~~~~~~ 性能日志：~~~~~~", "color:blue");
        console.time("生成数据模型并添加缓存");
        let uLines = generateUnionLines(this.audioLines, this.sysAudioLines);
        this.unionLines.splice(0, this.unionLines.length, ...uLines);
        this.pushOpState();
        console.timeEnd("生成数据模型并添加缓存");
        console.time("视图更新");
        this.$nextTick(() => {
          console.timeEnd("视图更新");
        });
      } else {
        let uLines = generateUnionLines(this.audioLines, this.sysAudioLines);
        this.unionLines.splice(0, this.unionLines.length, ...uLines);
        this.pushOpState();
      }
    },
    // ===============================================  步骤的不断缓存相关  ===================================
    pushOpState() {
      this.cacheService.putState(
        this.audioLines,
        this.sysAudioLines,
        this.unionLines
      );
    },
    clearOps() {
      this.cacheService.clearOps();
    },
    _useCacheProxy(opState) {
      if (window.showALog) {
        console.time("采用缓存生成数据");
        this._useCache(opState);
        console.timeEnd("采用缓存生成数据");
        console.time("视图更新");
        this.$nextTick(() => {
          console.timeEnd("视图更新");
        });
      } else {
        this._useCache(opState);
      }
    },
    _useCache(opState) {
      let { ulines, lines, sysLines } = opState;
      // 2020年6月19日17:19:48 开始所有缓存 右侧需要同步左侧的 paragraphKey ，此段代码需要等发布后保留7天
      // 发布时间？
      for (let ul of ulines) {
        let l = lines[ul.i];
        let sl = sysLines[ul.si];
        if (!l || !sl || !l.matched || !sl.matched) {
          continue;
        }
        sl.paragraphKey = l.paragraphKey;
      }

      // 使用缓存前: 记录选择的 selectedULine 的开始位置
      let selectedRDurStart;
      if (this.selectedULine && this.selectedULine.si >= 0) {
        const selectedRLine = this.sysAudioLines[this.selectedULine.si];
        if (selectedRLine.audioDur[1] > selectedRLine.audioDur[0]) {
          selectedRDurStart = selectedRLine.audioDur[0];
        }
      }
      // 使用缓存：
      this.audioLines.splice(0, this.audioLines.length, ...lines);
      this.sysAudioLines.splice(0, this.sysAudioLines.length, ...sysLines);
      this.unionLines.splice(0, this.unionLines.length, ...ulines);

      // 使用缓存后: 更新 selectedULine
      if (typeof selectedRDurStart === "number") {
        this.selectedULine = this.unionLines.find((ul) => {
          let rl = this.sysAudioLines[ul.si] || { audioDur: [0, 0] };
          let dur = rl.audioDur;
          return dur[1] > dur[0] && dur[0] === selectedRDurStart;
        });
      } else {
        this.selectedULine = null;
      }
    },
    async popAndUseOpState() {
      let opState;
      if (window.showALog) {
        console.log("%c\n~~~~~~~ 性能日志：~~~~~~", "color:blue");
        console.time("取缓存");
        opState = await this.cacheService.popThenGetLast();
        console.timeEnd("取缓存");
      } else {
        opState = await this.cacheService.popThenGetLast();
      }
      if (!opState || !opState.lines) {
        this.pushOpState();
      } else {
        if (opState.lines.every((ele) => ele.paragraphKey)) {
          this._useCacheProxy(opState);
        } else {
          await this.cacheService.clearOps();
          this.pushOpState();
        }
      }
    },
  },
  async mounted() {
    const articleId = this.audioFileName
      .match(/\((\d+)\)$/)
      .filter((ele) => /^\d+$/.test(ele))[0];
    // this.cacheService = new CacheService(this.bizId, articleId, this.audioKey);
    this.cacheService = await CacheService.getInstance(this.bizId, articleId, this.audioKey)


    this.audioPlayer = new Audio();
    this.audioPlayer.src = this.audioUrl;
    this.audioPlayer.onloadedmetadata = () => {
      this.info.audioDuration = this.audioDuration =
        Math.floor(this.audioPlayer.duration * 1000) - 50;
      this.isDataLoaded = true;
    };
    if (!this.unionLines.length) {
      // 没有进行初始化过：
      let opState;
      if (window.showALog) {
        console.log("%c\n~~~~~~~ 性能日志：~~~~~~", "color:blue");
        console.time("取缓存");
        opState = await this.cacheService.getLastState();
        console.timeEnd("取缓存");
        console.log(opState ? "" : "缓存为空");
      } else {
        opState = await this.cacheService.getLastState();
      }
      if (opState && opState.lines && opState.lines.every((ele) => ele.paragraphKey)) {
        this._useCacheProxy(opState);
      } else {
        if (opState) {
          await this.cacheService.clearOps();
        }
        autoMatch(this.audioLines, this.sysAudioLines);
        let uLines = generateUnionLines(this.audioLines, this.sysAudioLines);
        this.unionLines.splice(0, this.unionLines.length, ...uLines);
        this.pushOpState();
      }
    } else {
      this.audioDuration = this.info.audioDuration;
      this.isDataLoaded = true;
    }
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.wrapper {
  padding: 0.5em 0;
  font-size: 14px;
  margin: 0 2px;
  border: 1px solid #eee;
}
</style>
