<template>
  <EditorFrame :hb="hb" :showPreview="false">
    <div style="display:flex;">
      <EditorSider
        :activeIndex.sync="currentIndex"
        :len="audioLinesMap.length"
        :modifiable="false"
      />
      <div style="flex:1">
        <ContentManage
          v-for="(item,i) in audioLinesMap "
          :key="i"
          v-show="i===currentIndex"
          :audioLines="item"
        ></ContentManage>
      </div>
    </div>
    <template v-slot:buttons></template>
    <template v-slot:right-side>
      <Tabs value="name3">
        <TabPane label="当前配音" name="name3">
          <AudioManage
            v-for="(item,i) in audioLinesMap"
            :key="i"
            :hb="hb"
            :audioLines="item"
            :info="hb.attributeList[i]"
            v-show="i===currentIndex"
          />
        </TabPane>
        <TabPane label="版本记录" name="name1" v-if="hb.status!='0'">
          <colophon :taskInfo="taskInfo"></colophon>
        </TabPane>
      </Tabs>
    </template>
  </EditorFrame>
</template>

<script>
import * as ResolveService from "./components/resolve4audio";
// todo audioUploadService只有这里使用，待合并！
import audioUploadService from "./components/uploadBatch4audioLines";

import AudioManage from "./AudioManage";
export default {
  props: {
    hb: {},
    audioLinesMap: {},
    taskInfo: {}
  },
  components: { AudioManage },
  data() {
    return {
      isDisable: false
    };
  },
  methods: {
    validate() {
      for (let i = 0; i < this.audioLinesMap.length; i++) {
        for (let j = 0; j < this.audioLinesMap[i].length; j++) {
          if (
            !this.audioLinesMap[i][j].unread &&
            !this.audioLinesMap[i][j].audio
          ) {
            throw new Error(`第${i + 1}篇文章的音频文件不够！`);
          }
        }
      }
    },
    async validateAndSetdata() {
      this.validate();
      await audioUploadService(this.audioLinesMap);
      this.hb.audioMap = ResolveService.assemble(this.audioLinesMap);
    }
  }
};
</script>