// @ts-check
import Vue from 'vue'
const port = (new SharedWorker('js/opworker/opCacheWorker.js', { name: '配音步骤缓存服务' })).port

/**
 * @type {Map<number,[resolve:{(p):void},reject:{(p):void}]}
 */
const asyncFnMap = new Map()
let id = 0;
/**
 * @param {(value?: any) => void} resolve
 * @param {(reason?: any) => void} reject
 * @param {*} message 
 * @param {Transferable[]} [transfer] 
 */
function customPostMessage(resolve, reject, message, transfer) {
    message.reqId = id++
    asyncFnMap.set(message.reqId, [resolve, reject])
    port.postMessage(message, transfer)
}
port.onmessage = e => {
    const key = e.data.reqId
    if (!asyncFnMap.has(key)) {
        return;
    }
    const [resolve, reject] = asyncFnMap.get(key)
    asyncFnMap.delete(key)
    if (e.data.type === 'error') {
        reject(new Error(e.data.msg))
    } else {
        resolve(e.data.data)
    }
}

class CacheService {
    constructor(bizId, articleId, lastTime) {
        this.bizId = bizId
        this.articleId = articleId
        this.lastTime = lastTime
    }
    putState(lines, sysLines, ulines) {
        if (!ulines || !ulines.length) {
            return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
            customPostMessage(resolve, reject, {
                type: 'push',
                params: {
                    op: { lines, sysLines, ulines },
                    bizId: this.bizId,
                    articleId: this.articleId,
                    lastTime: this.lastTime
                }
            })
        })
    }
    popThenGetLast() {
        return new Promise((resolve, reject) => {
            customPostMessage(resolve, reject, {
                type: 'pop-then-last',
                params: {
                    bizId: this.bizId,
                    articleId: this.articleId,
                    lastTime: this.lastTime
                }
            })
        })
    }
    getLastState() {
        return new Promise((resolve, reject) => {
            customPostMessage(resolve, reject, {
                type: 'last',
                params: {
                    bizId: this.bizId,
                    articleId: this.articleId,
                    lastTime: this.lastTime
                }
            })
        })
    }
    clearOps() {
        return new Promise((resolve, reject) => {
            customPostMessage(resolve, reject, {
                type: 'clear',
                params: {
                    bizId: this.bizId,
                    articleId: this.articleId,
                    lastTime: this.lastTime
                }
            })
        })
    }
}
const CacheServiceFactory = Object.create(null)
CacheServiceFactory.getInstance = function (bizId, articleId, fileKey) {
    return new Promise((resolve, reject) => {
        customPostMessage(resolve, reject, {
            type: 'init',
            params: {
                bizId, articleId, fileKey
            }
        })
    }).then(res => {
        return new Proxy(new CacheService(bizId, articleId, res.articleLastModify), {
            get(obj, prop) {
                if (typeof obj[prop] !== 'function') {
                    return obj[prop]
                }
                return (...args) => {
                    let callRes = Reflect.apply(obj[prop], obj, args)
                    if (callRes instanceof Promise) {
                        callRes = callRes.then(res => {
                            if (res && res.articleLastModify) {
                                obj.lastTime = res.articleLastModify
                            }
                            return res;
                        }).catch(err => {
                            Vue.prototype.$Message.warning("【缓存错误】" + err.message)
                            return Promise.reject(err)
                        })
                    }
                    return callRes
                }
            }
        })
    }).catch(err => {
        Vue.prototype.$Message.warning("【缓存初始化错误】" + err.message)
        return Promise.reject(err)
    });
}
CacheServiceFactory.clear = function (bizId) {
    // todo 安全时间：给一个初始化时间initTime（页面初始化就采用date.now())，如果task.lastModify > initTime说明已过期，报错。
    return new Promise((resolve, reject) => {
        customPostMessage(resolve, reject, {
            type: 'clear',
            params: {
                bizId
            }
        })
    }).catch(err => {
        Vue.prototype.$Message.warning("【缓存清理错误】" + err.message)
        return Promise.reject(err)
    });
}
export default CacheServiceFactory