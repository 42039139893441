<template>
  <div class="wrapper">
    <BackgroundMusic :musicInfo="info" :contentLocked="false"/>
    <div class="header-wrapper">
      <AudioUpload :audioLines="audioLines" />
      <div class="header-tip">
        <div>为音频文件，命名以下对应的内容，一次性/单个上传</div>
        <div>单个音频最大不超过20M</div>
      </div>
      <div class="header-right-btn">
        <Button @click="delAllAudios">删除全部音频</Button>
        <Button class="play-all" @click="playFromInd()">
          <Icon class="play-all-icon" :type="canPlay ? 'md-arrow-dropright-circle' : 'ios-pause'" />播放全部
        </Button>
      </div>
    </div>

    <div v-for="(line,ind) in audioLines" :key="ind" class="item-wrapper">
      <div class="left-pno" :style="{color:line.unread?'#aaa':''}">{{ind+1}}.</div>
      <AudioManageItem
        style="flex:1"
        :line="line"
        :showVoiceSex="(hb&&hb.contentType) === 'cnFlying'"
        :playingLine="playingLine"
        @clickPlay="playAudio(line)"
        @clickPause="pauseAudio"
      />
    </div>
  </div>
</template>

<script>
import AudioUpload from "./components/AudioUpload";
import AudioManageItem from "./components/AudioManageItem";
export default {
  components: { AudioUpload, AudioManageItem },
  props: {
    hb: { required: true },
    audioLines: {
      required: true
    },
    info: {}
  },
  data() {
    return {
      playingLine: null,
      canPlay: true
    };
  },
  methods: {
    delAllAudios() {
      this.audioLines.forEach(line => {
        line.audio = null;
      });
    },

    playAudio(line) {
      return new Promise(resolve => {
        if (!line.audio) {
          resolve();
          return;
        }
        if (/^blob:/.test(this.$audio.src)) {
          console.log('释放')
          URL.revokeObjectURL(this.$audio.src);
        }
        if (line.file) {
          this.$audio.src = URL.createObjectURL(line.file);
        } else {
          this.$audio.src = line.audio.url;
        }

        this.$audio.play();
        this.canPlay = false;
        this.playingLine = line;
        this.$emit("audioText", line);
        this.$audio.onended = () => {
          this.canPlay = true;
          this.playingLine = null;
          this.$emit("audioText", { text: "", paragraphKey: "" });
          resolve();
        };
      });
    },
    pauseAudio() {
      this.canPlay = true;
      this.$audio.pause();
      this.playingLine = null;
      this.$emit("audioText", { text: "", paragraphKey: "" });
    },
    playFromInd(ind = 0) {
      if (ind >= this.audioLines.length) {
        this.pauseAudio();
        return;
      }
      this.canPlay = false;
      this.playAudio(this.audioLines[ind]).then(() => {
        this.playFromInd(ind + 1);
      });
    }
  }
};
</script>

<style scoped>
.wrapper {
  padding: 0.5em 1em;
  font-size: 14px;
  padding-bottom: 3.5em;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
}
.header-tip {
  width: 38em;
  padding: 0 1em;
}
.play-all {
  background: rgb(146, 101, 5);
  color: white;
}
.play-all-icon {
  font-size: 1.4em;
  margin-right: 0.3em;
}

.item-wrapper {
  display: flex;
}
.left-pno {
  width: 2em;
}
</style>