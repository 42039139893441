import axios from './axios'
import dictFullLowercase from './dict-full-lowercase';
import Vue from 'vue'

/**
 *
 * @param {{language,bg,cutPoint,ed,fileName}} params
 */
export function transAudio(params) {
    return axios({ method: 'get', timeout: 300000, url: '/common/content/manage/audioToTxt', params })
}



// const reg = /[\s,，。.!！:：;；“”‘’"'<>《》？?…、【】$￥&（）()]/g
/**
 * 两段字符串的拼音匹配情况。
 * 如果读音相同，返回[第一个字符串]。
 * 如果不相同，但第一个字符串前面部分str1和第二个字符串的读音相同，将第一个字符串拆开，剩余部分为str2,返回[str1,str2]
 * 如果既不相同，也无法包含第二个字符串，返回[]
 * @param {string} str
 * @param {string} matchStr
 * @returns {Array<string>}
 */
function _matchStrBySpeak(str, matchStr) {

    const arr1 = str.split('').map(char => dictFullLowercase[char] || char)
    const arr2 = matchStr.split('').map(char => dictFullLowercase[char] || char)

    let i1, i2;

    for (i1 = 0, i2 = 0; i1 < arr1.length && i2 < arr2.length; i1++, i2++) {
        // eg 1: 可忽略的字符
        if (typeof arr1[i1] === 'string' && /\W|_/.test(arr1[i1])) {
            i2--
            continue
        }
        if (typeof arr2[i2] === 'string' && /\W|_/.test(arr2[i2])) {
            i1--
            continue
        }
        // eg 2.1: 相等的读音字符串
        if (arr1[i1] === arr2[i2]) {
            continue
        }
        // eg 2.2: 不相等的读音字符串
        if (typeof arr1[i1] === 'string' && typeof arr2[i2] === 'string') {
            break;
        }
        // eg 3: 存在多音字的情况：
        if (typeof arr1[i1] === 'string') {
            arr1[i1] = [arr1[i1]]
        }
        if (typeof arr2[i2] === 'string') {
            arr2[i2] = [arr2[i2]]
        }
        let hasPyMatched = false;
        for (let py1 of arr1[i1]) {
            for (let py2 of arr2[i2]) {
                if (py1 === py2) {
                    hasPyMatched = true;
                    break;
                }
            }
            if (hasPyMatched) {
                break;
            }
        }
        if (hasPyMatched) {
            continue;
        } else {
            break;
        }
    }
    while (typeof arr1[i1] === 'string' && /\W|_/.test(arr1[i1])) {
        i1++
    }
    while (typeof arr2[i2] === 'string' && /\W|_/.test(arr2[i2])) {
        i2++
    }
    if (i2 >= arr2.length) {
        if (i1 >= arr1.length) {
            // 匹配
            return [str]
        } else {
            // 包含
            let res = [str.substring(0, i1), str.substring(i1)]
            return res
        }
    } else {
        // 不匹配
        return []
    }
}

/**
 * 会依次遍历，用右边的sysLines对左边的lines进行匹配拆分, 匹配成功的将设置两边的 matched 为 true
 * @param {Array<{text,paragraphKey,unreadFlags,matched,unread}>} lines
 * @param {Array<{text,paragraphKey,unreadFlags,matched,matchStr}>} sysLines
 */
export function autoMatch(lines, sysLines) {
    let nexj = 0;

    for (let i = 0; i < lines.length; i++) {
        for (let j = nexj; j < sysLines.length; j++) {
            let arr = _matchStrBySpeak(lines[i].text, sysLines[j].text);
            if (arr.length === 0) {
                // 右侧无法被匹配
                // 需求变动：一旦匹配不上立即退出匹配 -》 首次进入基本不匹配，6审打回(由于配音提交时 做了两侧的文本同步,即使左侧不读，或右侧采用上传文件方式，都是强制同步文本了的！)肯定是100%匹配上的。
                // 这里由 continue 改为 return
                return;
            }
            let phKey = lines[i].paragraphKey;
            sysLines[j].paragraphKey = phKey;
            sysLines[j].matched = true;
            lines[i].matched = true;
            if (arr.length === 1) {
                sysLines[j].matchStr = lines[i].text;
            } else {
                sysLines[j].matchStr = arr[0];
                lines[i].text = arr[0];
                // 更新flags：
                let flags = lines[i].unreadFlags.slice(arr[0].length);
                lines.splice(i + 1, 0, {
                    text: arr[1],
                    audio: null,
                    paragraphKey: phKey,
                    unreadFlags: flags,
                    matched: false,
                    unread: flags.every(ele => ele)
                });
            }
            // 自动匹配一律使用讯飞的标记：
            lines[i].unreadFlags = sysLines[j].unreadFlags || lines[i].unreadFlags.slice(0, arr[0].length)
            lines[i].unread = lines[i].unreadFlags.every(ele => ele)
            if (lines[i].unread) {
                // 全部不读的特殊情况：
                sysLines.splice(j, 1)
                lines[i].matched = false;
                j--

            }
            // 右侧被匹配，设置nextj:
            nexj = j + 1;
            break;
        }
    }
}

/**
 * 将lines和syslines顺序组装在一起，会考虑左边是否不读，以及两边的匹配情况，保证匹配的在一起，未匹配的靠前
 * @param {Array<{matched,unread}>} lines
 * @param {Array<{matched}>} sysLines
 */
export function generateUnionLines(lines, sysLines) {
    let i = 0;
    let si = 0;
    const wrapers = [];

    for (i = 0; i < lines.length; i++) {
        if (!lines[i].id) {
            lines[i].id = Math.random()
        }
    }
    for (i = 0; i < sysLines.length; i++) {
        // 每次重排数据，先清理掉右侧 全部内容为空的音频
        let { audioDur, file, key } = sysLines[i]
        if (audioDur[0] === 0 && audioDur[1] === 0 && !file && !key) {
            // 全部内容为空的音频（无音频片段，无本地文件 无网络文件key）
            sysLines.splice(i, 1)
            i--
            continue;
        }
        if (!sysLines[i].id) {
            sysLines[i].id = Math.random()
        }
    }

    // 需求更改：如果右侧没有音频（肯定是null），而左侧需要读的时候，右侧可以单独上传音频
    // 对于满足条件的情况下，将右侧的null替换为{key: '', url: '', ...} 这样的空值对象
    // 其实质是让右侧的sysLines足够，>= 左侧的lines。 而右侧是可以删除的
    // 注意：还要让此空值 真正加入 到 sysLines中。相关代码下方会标注：【代码标记1 勿删】
    i = 0
    while (i < lines.length && si < sysLines.length) {
        if (lines[i].unread) {
            // 左侧全部不读
            wrapers.push({ i, si: -1, id: lines[i].id.toString() });
            i++;
            continue;
        }
        if (
            (!lines[i].matched && !sysLines[si].matched) ||
            (lines[i].matched && sysLines[si].matched)
        ) {
            wrapers.push({ i, si, id: sysLines[si].id.toString() });
            i++;
            si++;
            continue;
        }
        if (!lines[i].matched) {
            // 【代码标记1 勿删】
            sysLines.splice(si, 0, {
                paragraphKey: '',
                text: '',
                audioDur: [0, 0],
                matchStr: '',
                id: Math.random(),
                url: '',
                speaker: '',
                isSelected: false,
                matched: false,
                unreadFlags: []
            })
            wrapers.push({ i, si, id: sysLines[si].id.toString() });
            i++;
            si++;
            continue;
        }
        if (!sysLines[si].matched) {
            wrapers.push({ i: -1, si, id: sysLines[si].id.toString() });
            si++;
        }
    }
    while (i < lines.length) {
        if (lines[i].unread) {
            wrapers.push({ i, si: -1, id: lines[i].id.toString() });
        } else {
            // 【代码标记1 勿删】
            sysLines.push({
                paragraphKey: '',
                text: '',
                audioDur: [0, 0],
                matchStr: '',
                id: Math.random(),
                url: '',
                speaker: '',
                isSelected: false,
                matched: false,
                unreadFlags: []
            })
            wrapers.push({ i, si, id: sysLines[si].id.toString() });
            si++;
        }
        i++;

    }
    while (si < sysLines.length) {
        wrapers.push({ i: -1, si, id: sysLines[si].id.toString() });
        si++;
    }
    return wrapers;
}

/**
 *
 * @param {{matched,unreadFlags,text}} line
 * @param {{matched,unreadFlags,matchStr}}  sysLine
 * @param {boolean} flag 设置关联还是不关联的标志
 */
export function setUnion(line, sysLine, flag) {
    if (flag) {
        if (sysLine.audioDur[1] <= 0 && !sysLine.fileSize) {
            Vue.prototype.$Message.warning('关联失败，右侧没有配音！')
            return;
        }
        line.matched = true;
        sysLine.matched = true;
        sysLine.matchStr = line.text;
        sysLine.unreadFlags = line.unreadFlags;
        sysLine.paragraphKey = line.paragraphKey;
    } else {
        line.matched = false;
        sysLine.matched = false;
        sysLine.matchStr = "";
        sysLine.unreadFlags = [];
        sysLine.paragraphKey = ""
    }
}
