<template>
  <div style="display:flex; align-items: center;width:100%;">
    <div style="flex:1;display:flex;align-items: center;">
      <div style="flex-shrink:1;line-height: 2em;">
        <div v-if="hasFragment">
          <span
            :class=" [isFragmentPlaying? 'text-success':'',line.file?'replaced-text':'']"
            v-if="line.matchStr||line.text"
          >{{line.matchStr&&line.matchStr.split('').filter((char,i)=>line.unreadFlags[i]?'':char).join('')||line.text}}</span>
          <span
            v-else
            class="text-warning"
            :class="line.file?'replaced-text':''"
          >获取后段文本失败，请试听后确认匹配时，点击“黄标”关联</span>
          <Icon
            v-if="isFragmentPlaying"
            @click.stop="$emit('clickPause')"
            class="play-ion"
            :class="line.file?'replaced-text':''"
            type="ios-pause"
          />
          <Icon
            v-else
            @click.stop="$emit('clickPlay')"
            title="播放配音"
            class="play-ion"
            :class="line.file?'replaced-text':''"
            type="md-arrow-dropright-circle"
          />
        </div>
        <div v-if="line.file">
          <span class="text-primary">{{line.file.name}}</span>
          <Icon
            v-show="!line.matched"
            class="text-error"
            @click.stop="delFile"
            style="font-size:1.125em"
            type="md-backspace"
            title="删除配音"
          />

          <Icon
            v-if="filePlaying"
            @click.stop="pauseFilePlaying"
            class="play-ion"
            type="ios-pause"
          />
          <Icon
            v-else
            title="播放配音"
            @click.stop="startFilePlaying"
            class="play-ion"
            type="md-arrow-dropright-circle"
          />
        </div>
        <div v-else-if="!hasFragment" class="text-mute">本句暂无音频。</div>
      </div>
      <div @click.stop>
        <VoiceSex v-if="chapter" v-model="chapter.voiceSex" style="width:4.5em" />
      </div>
    </div>
    <div style="width:4.2em;text-align:right; " @click.stop>
      <Upload
        style="display:inline-block;"
        action="noAction"
        accept="audio/*"
        :before-upload="proxyUpload"
        v-show="!line.matched"
      >
        <Icon class="play-ion" type="md-cloud-upload" />
      </Upload>
      <Checkbox style="margin-left:4px" v-model="line.isSelected" @input="$emit('select')" />
    </div>
  </div>
</template>

<script>
import { getUrl } from './audioKeyUrlMap';
import voiceKeyInjectMixin from './voiceKeyInjectMixin';
export default {
  props: {
    line: {},
    playingTime: {}
  },
  data() {
    return {
      player: new Audio(),
      filePlaying: false
    };
  },
  computed: {
    isFragmentPlaying() {
      return this.playingTime > this.line.audioDur[0] + 35 && this.playingTime < this.line.audioDur[1] - 35;
    },
    hasFragment() {
      // 区间范围是 [0,0]的目前有两种情况：
      // 所有文字完全不读，这个在自动匹配时已经全部去掉了；
      // 还有一种情况：右侧空白，需要能让其上传文件, 生成的空值！
      return this.line.audioDur[1] > 0;
    }
  },
  methods: {
    proxyUpload(file) {
      const url = URL.createObjectURL(file);
      this.player.src = url;
      this.line.file = file;
      this.line.fileSize = file.size;
      this.player.onloadedmetadata = () => {
        this.line.duration = Math.round(this.player.duration);
        // 应当在所有数据都更新完毕后，再通知外界
        this.$emit('fileChange');
        URL.revokeObjectURL(url);
      };
      this.line.key = '';
      this.line.url = '';
      return false;
    },
    delFile() {
      this.pauseFilePlaying();
      this.line.file = null;
      this.line.key = '';
      this.line.url = '';
      this.line.duration = null;
      this.line.fileSize = null;
      this.player.src = '';
      this.$emit('fileChange');
    },
    pauseFilePlaying() {
      this.player.pause();
      this.filePlaying = false;
    },
    startFilePlaying() {
      this.$emit('clickPause');
      setTimeout(() => {
        if (this.line.file && this.line.file.size) {
          this.player.src = URL.createObjectURL(this.line.file);
        } else if (this.line.key && getUrl(this.line.key)) {
          this.player.src = getUrl(this.line.key);
        } else {
          this.$Message.warning('文件可能失效，无法播放');
          return;
        }
        this.player.play();
        this.player.onended = () => {
          this.filePlaying = false;
        };
        this.filePlaying = true;
      }, 30);
    }
  },
  mounted() {
    if (!this.line.file) {
      this.$set(this.line, 'file', null);
    }
    if (this.line.key) {
      let url = getUrl(this.line.key);
      if (url) {
        this.line.file = { name: '初始音频文件.mp3', fromUrl: true };
      } else {
        // 说明是被后来 替换过或删除了的文件，已过期
        this.line.key = '';
        this.line.url = '';
        this.line.duration = null;
        this.line.fileSize = null;
      }
    }
  },
  watch: {
    playingTime() {
      if (this.filePlaying) {
        this.pauseFilePlaying();
      }
    }
  },
  mixins: [voiceKeyInjectMixin]
};
</script>

<style scoped>
.play-ion {
  cursor: pointer;
  color: rgb(146, 101, 5);
  font-size: 22px;
}
.i-btn {
  cursor: pointer;
  font-size: 20px;
  color: #888;
}
.replaced-text {
  /* text-decoration: line-through; */
  color: #aaa;
}
</style>
