<template>
  <EditorFrame :hb="hb" :onlyMiddle="true">
    <div style="display:flex;">
      <EditorSider
        :activeIndex.sync="currentIndex"
        :len="audioLinesMap.length"
        :modifiable="false"
      />
      <div style="flex:1">
        <div v-for="(item,i) in audioLinesMap " :key="i" v-show="i===currentIndex">
          <!-- <keep-alive> -->
          <SysManage
            v-show="i===currentIndex"
            :info="hb.attributeList[i]"
            :contentType="hb.contentType"
            :audioLines="audioLinesMap[i]"
            :sysAudioLines="sysAudioLinesMap[i]"
            :unionLines="uLinesMap[i]"
            :audioFileName="hb.processId+'('+ i + ')'"
            :audioUrl="hb.sourceAudioUrl[i]"
            :audioKey="hb.sourceAudioKey[i]"
            :bizId="hb.id"
          />
          <!-- </keep-alive> -->
        </div>
      </div>
    </div>
    <template v-slot:buttons>      
    </template>
  </EditorFrame>
</template>

<script>
import * as ResolveService from "./components/resolve4audio";
import CacheService from "./opCacheService";
import SysManage from "./SysManage";
export default {
  props: {
    hb: {},
    audioLinesMap: {},
    uLinesMap: {},
    sysAudioLinesMap: {},

  },
  components: { SysManage },
  data() {
    return {
    };
  },
  methods: {
    async validateAndSetdata() {
      let uploads = [];
      for (let ind = 0; ind < this.uLinesMap.length; ind++) {
        for (let uLine of this.uLinesMap[ind]) {
          const line = this.audioLinesMap[ind][uLine.i];
          const sysLine = this.sysAudioLinesMap[ind][uLine.si];
          if (!line || (!line.unread && !sysLine)) {
            throw new Error(`第${ind + 1}篇文章的配音没有一一对应`);
          }
          if (!line.unread && !line.matched) {
            throw new Error(`第${ind + 1}篇文章的配音没有全部关联`);
          }
          if (sysLine && sysLine.file && !sysLine.file.fromUrl) {
            uploads.push(sysLine);
          }
        }
      }

      //  上传 uploads
      if (uploads.length) {
        await this.$uploadService.uploadMutiple(
          uploads.map(ele => ele.file),
          true,
          ({ key, url }, ind) => {
            uploads[ind].file = undefined;
            uploads[ind].key = key;
            uploads[ind].url = url;
          }
        );
      }
      // 转换
      ResolveService.assembleSys(
        this.uLinesMap,
        this.audioLinesMap,
        this.sysAudioLinesMap
      ).forEach((ele, i) => {
        if (ele && ele.length) {
          this.hb.audioCommpareReturn[i] = ele;
        } else {
          console.warn(ele);
        }
      });

      if (
        this.hb.audioCommpareReturn.some(list =>
          list.some(ele => !ele.paragraphKey)
        )
      ) {
        throw new Error(
          "存在paragraphKey为空的item，不能提交。请联系管理人员!"
        );
      }
    },
    clearCache() {
      CacheService.clear(this.hb.id);
    }
  }
};
</script>
