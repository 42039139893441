<template>
  <div>
    <Upload multiple action="noAction" accept=".mp3, .wav, .midi" :before-upload="proxyUpload">
      <Button type="primary">上传音频</Button>
    </Upload>
  </div>
</template>

<script>
const MAX_FILE_SIZE = 20 << 20;
const specialReg = /[<\s>\\/*?|"‘’“”':^$,\-_，…—！!。：？,.]/g;

export default {
  props: {
    audioLines: {
      type: Array
    }
  },
  data() {
    return {
      seletFiles: [],
      isUploading: false
    };
  },
  methods: {
    proxyUpload(file) {
      this.seletFiles.push(file);
      if (!this.isUploading) {
        this.isUploading = true;
        setTimeout(() => {
          this._initUploadLines(this.audioLines, this.seletFiles);
          this.isUploading = false;
          this.seletFiles = [];
        }, 20);
      }
      return false;
    },
    /**
     *@param {Array<File>} allFiles
     */
    _initUploadLines(allLines, allFiles) {
      // 1/4 过滤无效文件
      let selectNum = allFiles.length;
      let availableFiles = allFiles.filter((file) => /audio/.test(file.type) && file.size <= MAX_FILE_SIZE);
      let availableNum = availableFiles.length;
      // 2/4 找到需要文件的地方
      let needFileLines = allLines.filter((line) => !line.audio);
      let needNum = needFileLines.length;
      // 3/4 进行排序,添加绑定
      let matchNum = 0;
      availableFiles.forEach((file) => {
        if (this._bindOneFile(needFileLines, file)) {
          matchNum++;
        }
      });
      console.log(`选择文件 ${selectNum} 个，其中有效文件 ${availableNum} 个`);
      console.log(`实际需要文件 ${needNum} 个，匹配成功 ${matchNum} 个`);
    },

    /**
     * @returns {Boolean} 是否成功绑定！
     */
    _bindOneFile(lines, file) {
      if (!lines || !lines[0]) {
        return;
      }
      // 下面要做特殊字符的替换，因为有些字符在内容中可以存在，但在文件名中不能存在，会自动去掉
      lines.forEach((item) => {
        item.textBuf = item.text.replace(specialReg, '').toLowerCase().trim();
      });

      let fileName = file.name.substring(0, file.name.lastIndexOf('.')).replace(specialReg, '').toLowerCase().trim();
      let nams = lines.map((item) => item.textBuf);
      console.log(fileName, '\n', file.name, '\n', nams);
      for (let i = 0; i < lines.length; i++) {
        if (!lines[i].audio && lines[i].textBuf === fileName) {
          const url = URL.createObjectURL(file);
          const audioElement = new Audio(url);
          audioElement.addEventListener('loadedmetadata', function () {
            lines[i].audio.duration = Math.round(audioElement.duration);
            URL.revokeObjectURL(url);
          });

          lines[i].audio = {
            name: file.name,
            key: ''
          };
          lines[i].file = file;
          return true;
        }
      }
      return false;
    }
  },

  mounted() {}
};
</script>

<style>
</style>