<template>
  <div v-if="editing">
    <Input
      @on-blur="modifyText($event,line)"
      :value="line.text"
      type="textarea"
      ref="textEditor"
      class="text-editor"
    />
  </div>
  <div v-else class="txt-line" :class="{'pop-active':hasPoper,'marked':isMarked}" ref="textPanel">
    <template v-if="editable">
      <Icon
        type="md-create"
        class="union-icon"
        style="color:rgb(146, 101, 15);font-size:17px;margin-bottom:5px"
        @click="startEditText"
      />
      <Poptip @on-popper-show="hasPoper=true" @on-popper-hide="hasPoper=false">
        <Icon type="ios-flag" class="union-icon" title="标记不读文字" style="color:rgb(146, 101, 15)" />
        <div slot="content" v-if="hasPoper" style="display:flex;flex-wrap:wrap">
          <div
            v-for="(char,charInd) in line.text.split('')"
            :key="charInd"
            style="text-align:center;"
            @click="setCharUnread($event,charInd)"
          >
            <div
              style="height:1.3em"
              @click="line.unreadFlags[charInd]=!line.unreadFlags[charInd]"
            >{{{'\n':'↓'}[char]||char}}</div>
            <Checkbox v-model="line.unreadFlags[charInd]" style="margin:0 .125em .25em"></Checkbox>
          </div>
        </div>
      </Poptip>
      <span v-if="lineTag" class="line-tag">{{lineTag}}</span>
      <span
        v-for="(char,charInd) in line.text.split('')"
        :key="charInd"
        @click="splitLine(line,charInd)"
        :class="{'deactive-char':line.unreadFlags[charInd],'non-split':charInd===line.text.length-1}"
        class="char"
      >
        <template v-if="char===' '">
          <span class="kongge"></span>
        </template>
        <template v-else-if="char==='\n'">↓</template>
        <template v-else>{{char}}</template>
      </span>

      <Icon
        style="margin-left: 0.5em;"
        class="union-icon"
        v-if="unionable"
        @click="$emit('unionNext')"
        type="md-remove-circle"
      />
    </template>
    <template v-else>
      <span v-if="lineTag" class="line-tag">{{lineTag}}</span>
      <span
        v-for="(char,charInd) in line.text.split('')"
        :key="charInd"
        :class="{'deactive-char':line.unreadFlags[charInd]}"
      >{{char===' '? "&nbsp;" :char}}</span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    line: {
      required: true
    },
    unionable: {
      default: false
    },
    editable: {
      default: true
    }
  },
  data() {
    return {
      lastClickUnreadInd: 0,
      hasPoper: false,
      editing: false
    };
  },
  computed: {
    lineTag() {
      if ("t" === this.line.paragraphKey) {
        return "标题";
      }
      if ("contentSource" === this.line.paragraphKey) {
        return "来源";
      }
      if ("dynasty" === this.line.paragraphKey) {
        return "朝代";
      }
      if ("author" === this.line.paragraphKey) {
        return "作者";
      }
      return "";
    },
    isMarked() {
      return this.$store.getters.getAudioItem === this.line;
    }
  },
  methods: {
    setCharUnread(e, ind) {
      setTimeout(() => {
        // 延迟一个帧 16毫秒，保证多个事件都触发完成，数据都正确了才行！
        if (e.shiftKey) {
          let min = Math.min(this.lastClickUnreadInd, ind);
          let max = Math.max(this.lastClickUnreadInd, ind);
          for (let i = min; i <= max; i++) {
            this.line.unreadFlags[i] = this.line.unreadFlags[
              this.lastClickUnreadInd
            ];
          }
        }
        this.lastClickUnreadInd = ind;
        this.$forceUpdate();
        if (this.line.unreadFlags.some(ele => !ele)) {
          this.line.unread = false;
          this.$emit("isAllCharsUnread", false);
        } else {
          this.line.unread = true;
          this.$emit("isAllCharsUnread", true);
        }
      }, 16);
    },
    splitLine(line, charInd) {
      if (charInd === line.text.length - 1) {
        return;
      }
      if (this.lineTag) {
        this.$Message.warning("不能拆分" + this.lineTag);
        return;
      }
      let str1 = line.text.substring(0, charInd + 1);
      let str2 = line.text.substring(charInd + 1);
      let unreadFlags1 = line.unreadFlags.slice(0, charInd + 1);
      let unreadFlags2 = line.unreadFlags.slice(charInd + 1);
      if (!str1.trim() || !str2.trim()) {
        this.$Message.warning("不能产生没有内容的行！");
        return;
      }
      let newLine = {
        paragraphKey: line.paragraphKey,
        voiceSex: line.voiceSex,
        audio: null,
        text: str2,
        matched: false,
        unread: !unreadFlags2.some(ele => !ele),
        unreadFlags: unreadFlags2
      };
      line.audio = null;
      line.text = str1;
      line.unreadFlags = unreadFlags1;
      line.unread = !unreadFlags1.some(ele => !ele);
      // 在ind后面插入新的行
      this.$emit("insertNewLineAfter", newLine);
    },
    modifyText(e, line) {
      this.editing = false;
      /** @type {string} */
      let txt = e.target.value;
      if (!txt.trim() && this.lineTag) {
        this.$Message.warning(this.lineTag + "内容不能修改为空！");
        return;
      }
      // 不同的行之间可以有换行符:
      const midText = txt.trim().replace(/\n/g, "");
      let startSpaces = txt.substring(0, txt.length - txt.trimStart().length);
      let endSpaces = txt.substring(txt.trimEnd().length);
      const oldStartSpaces = line.text.substring(
        0,
        line.text.length - line.text.trimStart().length
      );
      const oldEndSpaces = line.text.substring(line.text.trimEnd().length);
      if (!/\n/.test(oldStartSpaces)) {
        startSpaces = startSpaces.replace(/\n/g, "");
      }
      if (!/\n/.test(oldEndSpaces)) {
        endSpaces = endSpaces.replace(/\n/g, "");
      }

      line.text = startSpaces + midText + endSpaces;

      if (!line.text.trim()) {
        this.$emit("deleteSelf");
      } else {
        const len = this.line.text.length;
        if (line.unreadFlags.length < len) {
          for (let i = line.unreadFlags.length; i < len; i++) {
            line.unreadFlags.push(false);
          }
          line.unread = false;
        } else if (line.unreadFlags.length > len) {
          line.unreadFlags.splice(len, line.unreadFlags.length - len);
          line.unread = !line.unreadFlags.some(ele => !ele);
        }
        this.$emit("isAllCharsUnread", line.unread);
      }
    },
    startEditText() {
      let height = this.$refs.textPanel.scrollHeight;
      this.editing = true;
      this.$nextTick(() => {
        this.$refs["textEditor"].focus();
        let x = document.querySelector(".text-editor textarea");
        x.style.height = height + "px";
      });
    }
  }
};
</script>

<style scoped>
.line-tag {
  border: 1px solid red;
  color: red;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 6px;
  margin-right: 3px;
  transform: rotate(-3deg);
}
.txt-line {
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  letter-spacing: 2px;
  border-radius: 4px;
}
.pop-active {
  border: 1px solid rgb(146, 101, 15);
  border-radius: 5px;
  box-shadow: 0 0 7px -4px rgb(146, 101, 15) inset;
  background-color: rgb(250, 248, 244);
}
/* 单个字符的样式： */
.deactive-char {
  color: #bbb;
  font-style: italic;
  text-decoration: wavy;
}

/* ---3---- */
.union-icon {
  color: #888;
  font-size: 1.25em;
  cursor: pointer;
}
/* ---  */
.char {
  line-height: 1.5em;
  margin: 0.25em 0;
}
.char:not(.non-split) {
  cursor: pointer;
}
.char:hover {
  box-shadow: -3px 0 0 -1px #d14b4b inset;
}

/* 设置弹出层的背景色： */
::v-deep .ivu-checkbox:not(.ivu-checkbox-checked) .ivu-checkbox-inner {
  background-color: #f5f5f5;
}
::v-deep .ivu-poptip-inner {
  background: #eee;
}
::v-deep .ivu-poptip-arrow::after {
  border-top-color: #eee !important;
  border-bottom-color: #eee !important;
}
.text-editor ::v-deep textarea {
  /* resize: none; */
  height: auto;
}
.kongge {
  display: inline-block;
  width: 0.5em;
  height: 1em;
}
.marked {
  box-shadow: 0 0 0 1px #e62;
}
</style>