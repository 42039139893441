<template>
  <div v-if="line.unread" style="padding:0 .125em .5em;color:#aaa">
    <div>{{line.text}}</div>
    <div>无配音文本，无需配音文件</div>
  </div>
  <div v-else style="display:flex">
    <div class="middle-cont">
      <Tooltip content="已复制" placement="top" :disabled="hiddenCopyTip" class="txt-div">
        <span
          style="cursor:pointer"
          @click="copyText"
          title="点击复制"
          :class="playingLine!==line?'':'text-success'"
        >{{showText}}</span>
      </Tooltip>
      <VoiceSex v-if="showVoiceSex" v-model="line.voiceSex" style="width:4.5em" />
      <VoiceSex v-if="chapter" v-model="chapter.voiceSex" style="width:4.5em" />
      <div v-if="line.audio">
        <span class="txt-div text-primary">{{line.audio.name}}</span>
        <span
          style="color:#aaa"
          v-if="line.audio.fileSize"
        >&nbsp;({{line.audio.fileSize | fileSize}})</span>
        <Icon class="btn del-ion" type="md-backspace" @click="delAudio(line)" title="删除配音" />
      </div>
    </div>
    <div class="right-buttons-wrapper">
      <Icon type="ios-navigate" class="btn n-btn mute" @click="mark" />
      <Upload action="noAction" accept=".mp3, .wav, .midi" :before-upload="proxyUpload">
        <Icon class="btn n-btn" type="md-cloud-upload" />
      </Upload>
      <Icon
        class="btn n-btn mute"
        :class="{'inactive':!line.audio||!line.audio.key}"
        type="md-cloud-download"
        @click="download(line.audio)"
      />

      <Icon
        v-if="playingLine!==line"
        @click="$emit('clickPlay')"
        title="播放配音"
        class="btn n-btn"
        :class="{'inactive':!line.audio}"
        type="md-arrow-dropright-circle"
      />
      <Icon v-else class="btn n-btn" type="ios-pause" @click="$emit('clickPause')" />
    </div>
  </div>
</template>

<script>
import voiceKeyInjectMixin from "./voiceKeyInjectMixin";
export default {
  props: {
    showVoiceSex: { default: false },
    line: {},
    playingLine: {}
  },
  data() {
    return { hiddenCopyTip: true };
  },
  computed:{
    showText(){
      return this.line.text.split("").filter((char, i) => !this.line.unreadFlags[i]).join("")
    }
  },
  methods: {
    delAudio(line) {
      line.audio = null;
    },
    proxyUpload(file) {
      this.line.audio = {
        name: file.name,
        key: ""
      };
      this.line.file = file;
      const url = URL.createObjectURL(file);
      const audioElement = new Audio(url);
      audioElement.addEventListener("loadedmetadata", () => {
        this.line.audio.duration = Math.round(audioElement.duration);
        URL.revokeObjectURL(url);
      });

      return false;
    },
    mark() {
      this.$store.commit("setAudioItem", this.line);
    },
    copyText(e) {
      let input = document.createElement("input");
      input.value = e.target.innerText;

      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.hiddenCopyTip = false;
      setTimeout(() => {
        this.hiddenCopyTip = true;
      }, 1000);
    },
    download(audioObj) {
      if (audioObj.downloaded) {
        this.$Message.info("请勿重复下载");
        return;
      }
      this.$set(audioObj, "downloaded", true);

      const xhr = new XMLHttpRequest();
      xhr.open("get", audioObj.url);
      xhr.responseType = "blob";
      xhr.send();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          setTimeout(() => {
            audioObj.downloaded = false;
          }, 2000);
          if (xhr.status === 200) {
            let url = URL.createObjectURL(xhr.response);
            const link = document.createElement("a");
            link.href = url;
            console.log(audioObj)
            link.download = audioObj.name || `下载.mp3`;   // 
            link.style.display = "none";

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          } else {
            this.$Message.warning("音频下载失败，请稍后重试");
          }
        }
      };
    }
  },

  beforeMount() {},

  watch: {
    "line.unreadFlags": {
      handler() {
        setTimeout(() => {
          this.$forceUpdate();
        }, 16);
      },
      deep: true
    }
  },
  mixins: [voiceKeyInjectMixin]
};
</script>

<style scoped>
.middle-cont {
  width: calc(100% - 10.5em);
  padding-bottom: 1.2em;
}
.right-buttons-wrapper {
  width: 12em;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2em;
}

/* .mute{
  opacity: .3;
  transition: opacity linear .9s;
}
.right-buttons-wrapper:hover .mute{
  opacity: 1;
} */

.btn {
  cursor: pointer;
}
.btn.inactive {
  cursor: not-allowed;
}
.n-btn {
  color: rgb(146, 101, 5);
  font-size: 2em;
}
.n-btn.inactive {
  color: rgb(180, 180, 180);
}
.del-ion {
  color: #9b3333;
  font-size: 1.5em;
}
</style>