<template>
  <div v-if="isSys!==null">
    <div v-if="/Intensive/.test(hb.contentType)" class="tabs-wrapper tabs-hacker">
      <div class="tab" :class="{'active':uploadType==='audio'}" @click="uploadType='audio'">音频</div>
      <div class="tab" :class="{'active':uploadType==='video'}" @click="uploadType='video'">视频</div>
    </div>
    <NewAudioPage
      ref="sysAudio"
      :hb="hb"
      :audioLinesMap="audioLinesMap"
      :uLinesMap="uLinesMap"
      :sysAudioLinesMap="sysAudioLinesMap"
      v-show="uploadType==='audio'"
      v-if="isSys"
    />
    <OldAudioPage
      ref="normalAudio"
      :hb="hb"
      :audioLinesMap="audioLinesMap"
      :taskInfo="taskInfo"
      v-show="uploadType==='audio'"
      v-else
    />
    <template v-if="/Intensive/.test(hb.contentType)">
      <VideoPage
        v-show="uploadType==='video'"
        :hb="hb"
        :isSys="isSys"
        :taskInfo="taskInfo"
        :postSubmit="submit"
      />
    </template>
    <div class="buttons-wraper">
      <template v-if="this.$route.query.rewriteId">
        <Button class="mybtn" type="error" @click="drop">放弃</Button>
        <Button class="mybtn" @click="save">保存</Button>
        <Button class="mybtn" type="primary" @click="submit">提交</Button>
      </template>
      <template v-else>
        <DeleteHbButton :hb="hb" class="mybtn" :afterDelCallback="clearCache" />
        <Button class="mybtn" type="primary" @click="submit">提交</Button>
      </template>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import { getHbTaskView } from "../api/hbService";
import {
  rewrite_drop,
  rewrite_save,
  rewrite_submit,
  getRewriteContent
} from "../api/startTaskService";
// todo
import lingYunApi from "../api/api";
// todo
import { putData } from "./components/audioKeyUrlMap";
import * as ResolveService from "./components/resolve4audio";
import editorMixin from "../views/editorMixin";
// todo
import voiceKeyProvideMixin from "./components/voiceKeyProvideMixin";
import OldAudioPage from "./OldAudioPage";
import NewAudioPage from "./NewAudioPage";
import VideoPage from "./VideoPage";
export default {
  props: {},
  components: { OldAudioPage, NewAudioPage, VideoPage },
  data() {
    return {
      hb: {},
      audioLinesMap: [],
      sysAudioLinesMap: [],
      uLinesMap: [],
      isSys: null,
      taskInfo: [],
      uploadType: "audio" // audio video
    };
  },
  computed: {},
  methods: {
    initData() {
      let params = {
        taskId: this.$route.query.taskId,
        processId: this.$route.query.processId,
        bizId: this.$route.query.bizId || this.$route.query.rewriteId,
        contentType: this.$route.query.contentType,
        taskName: this.$store.getters.getRoleCode
      };
      params.id = params.bizId;
      let initFn = this.$route.query.rewriteId
        ? getRewriteContent
        : getHbTaskView;
      this.$Spin.show();
      initFn(params, () => {
        this.$Spin.hide();
      })
        .then(res => {
          let hb = res.content || res;
          this.taskInfo = res.taskInfo || [];
          hb.taskName = 5;
          if (this.$route.query.rewriteId) {
            hb.taskId = "";
            hb.processId = "";
            hb.audioCommpareReturn = [];
          }
          this.isSys = !!(
            hb.audioCommpareReturn &&
            hb.audioCommpareReturn[0] &&
            hb.audioCommpareReturn[0].length
          );

          this.audioLinesMap = ResolveService.factory(hb);
          this.sysAudioLinesMap = ResolveService.factorySys(hb);
          // 这里需要设置 key - url
          putData(this.sysAudioLinesMap);
          for (let i = 0; i < this.audioLinesMap.length; i++) {
            this.uLinesMap.push([]);
          }
          this.initVoiceSexConfig(hb, this.audioLinesMap);
          this.hb = hb;
        })
        .catch(err => {
          this.$Message.warning(err.message || err);
        })
        .finally(() => {
          this.$Spin.hide();
        });
    },
    async submitCore() {
      this.hb.taskName = 5;
      this.hb.pass = this.$route.query.rewriteId ? 11 : 0;

      let submitFn;
      if (this.$route.query.rewriteId && /Teaching/.test(this.hb.contentType)) {
        submitFn = lingYunApi.teaching_rewrite_submit; // 只有教材
      } else if (this.$route.query.rewriteId) {
        submitFn = rewrite_submit;
      } else {
        submitFn = lingYunApi.postSubmit;
      }

      this.writeVoiceSex(this.hb);
      ResolveService.writeBack(this.hb);
      await submitFn(this.hb);

      this.$Message.success("提交成功");
      this.isDropTask = false;
      setTimeout(() => {
        this.$router.back();
      }, 1000);
    },

    clearCache() {
      if (this.$refs.sysAudio) {
        this.$refs.sysAudio.clearCache();
      }
    },
    drop() {
      // 放弃重写
      console.log(this.hb);
      // return;
      rewrite_drop({
        parentId: this.hb.parentId || this.hb.id,
        parentProcessId: this.hb.parentProcessId || this.hb.processId,
        contentType: this.hb.contentType,
        taskName: this.hb.taskName
      })
        .then(() => {
          this.$router.back();
        })
        .catch(err => {
          this.$Message.warning(typeof err === "string" ? err : err.message);
        });
    },
    async save() {
      // 不能用submitCore
      let beforeSubmit = (this.$refs.sysAudio || this.$refs.normalAudio)
        .validateAndSetdata;
      try {
        await beforeSubmit();
        await this.uploadMusicAndVideo();
        this.writeVoiceSex(this.hb);
        ResolveService.writeBack(this.hb);
        let saveFn = /Teaching/.test(this.hb.contentType)
          ? lingYunApi.teaching_rewrite_save
          : rewrite_save;
        let { id, parentProcessId, parentId } = await saveFn(this.hb);
        this.hb.id = id;
        this.hb.parentProcessId = parentProcessId;
        this.hb.parentId = parentId;
        this.$Message.success("保存成功");
      } catch (err) {
        console.error(err);
        this.$Message.warning(typeof err === "string" ? err : err.message);
      }
    },

    async submit() {
      let beforeSubmit = (this.$refs.sysAudio || this.$refs.normalAudio)
        .validateAndSetdata;
      this.$Spin.show();
      try {
        await beforeSubmit();
        await this.uploadMusicAndVideo();
        await this.submitCore();
        this.clearCache();
      } catch (err) {
        console.error(err);
        this.$Message.warning(typeof err === "string" ? err : err.message);
      } finally {
        this.$Spin.hide();
      }
    },
    async uploadMusicAndVideo() {
      const backgroundMusicUploads = [];
      const videoUps = [];
      for (let item of this.hb.attributeList) {
        let videoFiles = item.contentDivList.filter(
          ele => ele.divStyle === "video" && ele.file
        );
        videoUps.push(...videoFiles);
        if (item.musicFile) {
          backgroundMusicUploads.push(item);
        }
      }
      // 上传背景音乐 和 视频：
      if (videoUps.length) {
        await this.$uploadService.uploadMutiple(
          videoUps.map(ele => ele.file),
          true,
          ({ key, url }, ind) => {
            videoUps[ind].file = undefined;
            videoUps[ind].key = key;
            videoUps[ind].url = url;
          }
        );
      }
      if (backgroundMusicUploads.length) {
        await this.$uploadService.uploadMutiple(
          backgroundMusicUploads.map(ele => ele.musicFile),
          true,
          (res, ind) => {
            backgroundMusicUploads[ind].musicFile = undefined;
            backgroundMusicUploads[ind].musicKey = res.key;
            backgroundMusicUploads[ind].musicUrl = res.url;
          }
        );
      }
    }
  },

  beforeMount() {
    this.initData();
    this.currentIndex = 0;
  },
  mixins: [editorMixin, voiceKeyProvideMixin]
};
</script>

<style scoped>
.tabs-hacker {
  margin-top: -55px;
  margin-bottom: 31px;
}
.buttons-wraper {
  padding: 1.5em 0 1em;
  box-shadow: 0 -5px 8px -8px #999;
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  background: white;
}
.mybtn {
  width: 7em;
  margin: 0 1em;
}
</style>