<template>
  <div style="padding:1em 0;border-bottom:1px solid #eee;">
    <div class="line">
      <div class="line-item1 line" style="justify-content:flex-end;">
        <Icon
          v-if="!isPlaying||playingTimeCur<40||playingTimeCur>duration-40"
          @click="$emit('clickPlay',[playingTimeCur>=duration-150?0:playingTimeCur,duration])"
          class="play-ion"
          type="md-arrow-dropright-circle"
        />
        <Icon v-else @click="$emit('clickPause')" class="play-ion" type="ios-pause" />
        <span style="padding-left:1em;">0</span>
      </div>
      <div class="line-item2">
        <Slider
          :min="0"
          :max="duration"
          :value="playingTimeCur"
          @on-change="isPlaying ? $emit('clickPlay',[$event,duration]):$emit('update:playingTimeCur',$event)"
        ></Slider>
      </div>
      <div class="line-item3">{{duration}}ms</div>
    </div>
    {{duration}}
    <div v-if="uLine" style="padding-top:10px">
      <div class="line" style="margin-bottom:-36px;">
        <div class="line-item1"></div>
        <div class="line-item2">
          <Slider
            v-if="showEdit&&showStart"
            class="start-set"
            :value="setStartOfSeg"
            style="background:red;"
            :min="editRange[0]"
            :max="editRange[1]"
            @on-change="onStartChange"
          ></Slider>
        </div>
        <div class="line-item3"></div>
      </div>
      <div class="line">
        <div class="line-item1">{{editRange[0]}}ms</div>
        <div class="line-item2">
          <Slider
            v-model="dur"
            v-if="showEdit"
            :min="editRange[0]"
            :max="editRange[1]"
            :marks="orignalDur"
            range
            show-tip="always"
            @on-change="onDurChange"
          ></Slider>
        </div>
        <div class="line-item3">{{editRange[1]}}ms</div>
      </div>
      <div class="line" style="margin-top:10px;">
        <div class="line-item1"></div>
        <div class="line-item2">
          <Button @click="$emit('clickPlay',[dur[0],dur[1]])" title="从当前起点到断点之间进行播放">播放节点</Button>
          <Button
            @click="$emit('clickPlay',[setStartOfSeg,dur[1]])"
            title="从当前“蓝色竖线”的位置到断点之间进行播放"
          >指定播放</Button>
          <Button
            :type="keySet===1?'primary':'default'"
            @click="keySet===1?keySet=0:keySet=1"
            title="当前音频的起始时间位置（选中后，可键盘左右键，每次40毫秒）"
          >起点</Button>
          <Button
            :type="keySet===2?'primary':'default'"
            @click="keySet===2?keySet=0:keySet=2"
            title="当前音频要剪辑的时间位置（选中后，可键盘左右键，每次40毫秒）"
          >断点</Button>
          <Button @click="reset" title="还原起点和断点的初始位置">重置</Button>
          <Button
            @click="submit"
            title="点击对当前起点与断点之间进行剪辑"
            :disabled="dur[0]===rLine.audioDur[0]&&dur[1]===rLine.audioDur[1]"
          >确定</Button>
        </div>
        <div class="line-item3">
          <Button @click="$emit('update:uLine',null)" title="取消当前操作">取消</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { transAudio } from "./sysAudioService";
export default {
  props: {
    duration: {
      required: true,
    },
    uLine: {},
    sysLines: {
      type: Array,
    },
    playingTimeCur: {},
    isPlaying: {},
    contentType: {},
    audioFileName: {},
  },
  data() {
    return {
      dur: [0, 0],
      rLine: null,
      setStartOfSeg: 0,
      keySet: 2, // 0 无，1 起点，2 终点
      showStart: true,
      showEdit: true,
    };
  },
  computed: {
    editRange() {
      if (!this.uLine) {
        return [0, 0];
      }
      const si = this.uLine.si;
      const rLine = this.sysLines[si];
      let preMax, nextMin, i;

      // ---------- compute prMax:
      for (i = si - 1; i >= 0; i--) {
        let [s, e] = this.sysLines[i].audioDur;
        if (s !== 0 || e != 0) {
          preMax = e;
          break;
        }
      }
      if (preMax === undefined) {
        preMax = 0;
      }

      // ---------- compute nextMin:
      for (i = si + 1; i < this.sysLines.length; i++) {
        let [s, e] = this.sysLines[i].audioDur;
        if (s !== 0 || e != 0) {
          nextMin = s;
          break;
        }
      }
      if (nextMin === undefined) {
        nextMin = this.duration;
      }

      let res = [
        Math.min(rLine.audioDur[0], preMax),
        Math.max(rLine.audioDur[1], nextMin),
      ];
      // 为保证可编辑性，首位最多扩展2s
      return [
        Math.max(rLine.audioDur[0] - 2000, res[0]),
        Math.min(rLine.audioDur[1] + 2000, res[1]),
      ];
    },
    orignalDur() {
      if (!this.rLine) {
        return [];
      }
      return {
        [this.rLine.audioDur[0]]: "",
        [this.rLine.audioDur[1]]: "",
      };
    },
  },

  methods: {
    async splitAudio(bg, cutPoint, ed) {
      return await transAudio({
        language: this.contentType.substring(0, 2),
        bg,
        cutPoint,
        ed,
        fileName: this.audioFileName,
      })
        .then((res) => {
          return res;
        })
        .catch(() => {
          return [[], []];
        });
    },
    onDurChange() {
      if (this.setStartOfSeg < this.dur[0]) {
        this.setStartOfSeg = this.dur[0];
      }
      if (this.setStartOfSeg > this.dur[1]) {
        this.setStartOfSeg = this.dur[1];
      }
    },
    onStartChange(start) {
      if (start >= this.dur[0] && start <= this.dur[1]) {
        this.setStartOfSeg = start;
      } else {
        if (start < this.dur[0]) {
          this.setStartOfSeg = this.dur[0];
        } else if (start > this.dur[1]) {
          this.setStartOfSeg = this.dur[1];
        }
        this.showStart = false;
        this.$nextTick(() => {
          this.showStart = true;
        });
      }
    },
    reset() {
      this.dur = [...this.rLine.audioDur];
      this.onDurChange();
    },
    submit() {
      if (this.rLine.audioDur[1] <= this.dur[1] + 150) {
        this.rLine.audioDur = this.dur;
        this.$emit("hasOperate");
        this.$emit("update:uLine", null);
        return;
      }
      this.$Spin.show();
      this.splitAudio(this.dur[0], this.dur[1], this.rLine.audioDur[1]).then(
        (res) => {
          this.$Spin.hide();
          console.log(res);
          this.$emit("insertRLineAfter", {
            paragraphKey: "",
            text: res[1].join(",") || "",
            audioDur: [this.dur[1], this.rLine.audioDur[1]],
            matchStr: "",
            key: "",
            url: "",
            isSelected: false,
            speaker: "byCut",
            matched: false,
            unreadFlags: [],
          });

          this.rLine.audioDur = this.dur;
          this.rLine.text = res[0].join(",").trim() || this.rLine.text;
          this.$nextTick(() => {
            this.$emit("update:uLine", null);
          });
        }
      );
    },

    /**
     * @param {KeyboardEvent} e
     */
    keySetFn(e) {
      if (this.keySet === 0) {
        return;
      }
      if (e.keyCode !== 37 && e.keyCode !== 39) {
        return;
      }
      let move = e.keyCode === 37 ? -40 : 40;
      if (this.keySet === 1) {
        this.dur[0] += move;
        //不能大于 dur[1],不能小于min
        if (this.dur[0] > this.dur[1]) {
          this.dur[0] = this.dur[1];
        }
        if (this.dur[0] < this.editRange[0]) {
          this.dur[0] = this.editRange[0];
        }
      } else {
        this.dur[1] += move;
        //不能小于 dur[0],不能大于max
        if (this.dur[1] < this.dur[0]) {
          this.dur[1] = this.dur[0];
        }
        if (this.dur[1] > this.editRange[1]) {
          this.dur[1] = this.editRange[1];
        }
      }
      this.showEdit = false;
      this.onDurChange();
      this.$nextTick(() => {
        this.showEdit = true;
      });
      e.stopPropagation();
      e.preventDefault();
    },
  },
  mounted() {
    window.addEventListener("keydown", this.keySetFn);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keySetFn);
  },
  watch: {
    uLine: {
      handler() {
        if (!this.uLine) {
          this.$emit("update:uLine", null);
          return;
        }
        if (this.sysLines[this.uLine.si].matched) {
          this.$emit("update:uLine", null);
          return;
        }
        this.rLine = this.sysLines[this.uLine.si];
        this.keySet = 2;
        this.setStartOfSeg = this.rLine.audioDur[0];
      },
      immediate: true,
      deep: true,
    },
    rLine: {
      handler() {
        if (!this.rLine) {
          return;
        }
        this.dur = [...this.rLine.audioDur];
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.play-ion {
  cursor: pointer;
  color: rgb(146, 101, 5);
  font-size: 22px;
  margin-left: 0.5em;
  font-size: 30px;
}
.line {
  display: flex;
  align-items: center;
}
.line-item1 {
  width: 8em;
  padding-right: 1em;
  text-align: right;
}
.line-item2 {
  flex: 1;
}
.line-item3 {
  width: 8em;
  padding-left: 1em;
  text-align: left;
}

.start-set ::v-deep .ivu-slider-button {
  background-color: #57a3f3;
  border-radius: 0;
  width: 6px;
  height: 32px;
  transform: translateY(-2px);
}
.start-set ::v-deep .ivu-slider-button:hover,
.start-set ::v-deep .ivu-slider-button:focus {
  transform: translateY(-2px);
  border: none;
}
.start-set ::v-deep .ivu-tooltip-popper {
  /* top: -72px !important; */
  z-index: 999;
}
</style>