const map = {}
// 
export function putData(linesArr) {
    linesArr.forEach(lines => {
        lines.forEach(ln => {
            map[ln.key] = ln.url
        })
    });
}
export function getUrl(key){
    return map[key]||''
}