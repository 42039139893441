<template>
  <EditorFrame :hb="hb" :onlyMiddle="isSys">
    <IntensiveEditor :hb="hb" :onlyVideoUpload="true" :contentLocked="true" />
    <template v-slot:buttons>
    </template>
    <template v-slot:right-side>
      <Tabs value="name1">
        <TabPane label="版本记录" name="name1" v-if="hb.status!='0'">
          <colophon :taskInfo="taskInfo"></colophon>
        </TabPane>
      </Tabs>
    </template>
  </EditorFrame>
</template>

<script>
export default {
  props: {
    hb: {},
    taskInfo: {},
    isSys: {},
    postSubmit: {
      required: true,
      type: Function
    }
  },
  methods: {}
};
</script>

<style>
</style>