import vue from 'vue'
async function executeUpload(allLines) {
    let uploadLines = []
    for (let line of allLines) {
        if (line.unread) {
            if (line.audio) {
                line.audio.key = ''
                line.audio.name = ''
                line.audio.duration = 0
            }
        } else if (!!line.file) {
            uploadLines.push(line)
        }
    }
    if (!uploadLines.length) {
        return;
    }
    
    return await vue.prototype.$uploadService.uploadMutiple(uploadLines.map(ele=>ele.file), true, (res, ind) => {
        uploadLines[ind].file = undefined
        uploadLines[ind].audio.key = res.key;
        uploadLines[ind].audio.url = res.url;
    });
}

/**
 * 
 * @param {Array<Array>} linesArr 
 */
export default async function (linesArr) {
    let reqs = [];
    linesArr.forEach(lines => {
        reqs.push(executeUpload(lines))
    })
    await Promise.all(reqs)
}