<template>
  <div class="item-wrap">
    <div style="flex:1;padding: 0 10px;">
      <ContentManageItem
        v-if="lines[uLine.i]"
        :line="lines[uLine.i]"
        :key="lines[uLine.i].id"
        :unionable="leftUnionable"
        :editable="!lines[uLine.i].matched"
        @isAllCharsUnread="unreadChange($event)"
        @unionNext="unionLLineNext(uLine.i)"
        @insertNewLineAfter="insertLLineNext(uLine.i,$event)"
        @deleteSelf="deleteLLine(uLine.i)"
      />
    </div>
    <div style="width:10em;padding:0 .25em;display:flex;">
      <div style="flex:1"></div>
      <div style="flex:1">
        <Icon
          v-if="lines[uLine.i]&&lines[uLine.i].matched"
          class="text-success i-btn"
          type="md-checkmark-circle"
          title="取消关联"
          @click="unUnion"
        />
        <Icon
          v-else-if="lines[uLine.i]&&sysLines[uLine.si]"
          type="md-help-circle"
          class="text-warning i-btn"
          title="关联"
          @click="union"
        />
      </div>
      <div style="flex:1"></div>
    </div>
    <div
      @click="selectRLine"
      style="flex:1; align-self:stretch; display:flex; padding:0 10px;"
      :class="sysLines[uLine.si]&&sysLines[uLine.si]===activeRLine?'active':''"
      :style="{backgroundColor:isCut?'#dfd':''}"
    >
      <SysAudioManageItem
        v-if="sysLines[uLine.si]"
        :line="sysLines[uLine.si]"
        :playingTime="playingTimeCur"
        :key="sysLines[uLine.si].id"
        @clickPlay="$emit('clickPlay')"
        @clickPause="$emit('clickPause')"
        @select="$emit('select',uLine.si)"
        @fileChange="$emit('hasOperate')"
      />
      <div
        v-else-if="lines[uLine.i].unread"
        style="color:#aaa;display:flex;align-items:center;"
      >本行原文为无配音文本，无需匹配音频</div>
    </div>
  </div>
</template>

<script>
import SysAudioManageItem from "./SysAudioManageItem";
import ContentManageItem from "./ContentManageItem";
import { setUnion } from "./sysAudioService";
export default {
  props: {
    uLine: {
      required: true
    },
    lines: {
      type: Array
    },
    sysLines: {
      type: Array
    },
    playingTimeCur: {},
    activeRLine: {}
  },
  data() {
    return {};
  },
  computed: {
    leftUnionable() {
      // 当前和下一个 都存在，都未matched，且paragraphKey相等 才可以合并
      let i = this.uLine.i;
      if (i < 0 || i + 1 >= this.lines.length) {
        return false;
      }
      if (this.lines[i].matched || this.lines[i + 1].matched) {
        return false;
      }
      return this.lines[i].paragraphKey === this.lines[i + 1].paragraphKey;
    },
    isCut() {
      let si = this.uLine.si;
      if (si >= 0 && this.sysLines[si].speaker === "byCut") {
        return true;
      }
      if (
        si + 1 < this.sysLines.length &&
        this.sysLines[si + 1].speaker === "byCut"
      ) {
        return true;
      }
      return false;
    }
  },
  components: { SysAudioManageItem, ContentManageItem },
  methods: {
    union() {
      setUnion(this.lines[this.uLine.i], this.sysLines[this.uLine.si], true);
      this.$emit("hasOperate");
    },
    unUnion() {
      setUnion(this.lines[this.uLine.i], this.sysLines[this.uLine.si], false);
      // 解关联后，需要让两边向上移动
      this.$emit("regenerateUlines");
    },
    unreadChange(flag) {
      if ((flag && this.uLine.si >= 0) || (!flag && this.uLine.si < 0)) {
        this.$emit("regenerateUlines");
      } else {
        this.$emit("hasOperate");
      }
    },
    insertLLineNext(ind, newLine) {
      this.lines.splice(ind + 1, 0, newLine);
      this.$emit("regenerateUlines");
    },
    unionLLineNext(i) {
      let line1 = this.lines[i];
      let line2 = this.lines[i + 1];
      line1.text += line2.text;
      line1.unreadFlags = line1.unreadFlags.concat(line2.unreadFlags);
      line1.unread = line1.unreadFlags.every(ele => ele);
      this.lines.splice(i + 1, 1);
      this.$emit("regenerateUlines");
    },
    deleteLLine(i) {
      this.lines.splice(i, 1);
      this.$emit("regenerateUlines");
    },
    selectRLine() {
      const sysLine = this.sysLines[this.uLine.si];
      if (
        !sysLine ||
        (sysLine.audioDur[0] === 0 && sysLine.audioDur[1] === 0)
      ) {
        return;
      }
      if (sysLine.matched) {
        this.$Message.warning("已关联好的配音不可选中编辑！");
        return;
      }
      this.$emit("selectRLine");
    }
  },
  mounted() {}
};
</script>

<style scoped >
.item-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3.8em;
}
.item-wrap:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.i-btn {
  cursor: pointer;
  font-size: 20px;
}
.active {
  /* background: #3f9dff; */
  /* box-shadow: 0 0 75px -40px inset #2e8cf0, 0 0 8px -3px inset #2e8cf0,
    0 0 15px -5px inset #2e8cf0; */
  outline: 1px solid #2e8cf0;
  background-color: rgba(46, 140, 240, 0.2);
}
</style>