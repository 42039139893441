import Vue from "vue";
export default {
    inject: ["voiceSexByParagraphKey"],
    computed: {
        chapter() {
            return Vue.observable((this.voiceSexByParagraphKey[this.currentIndex] || []).find(ele =>
                ele.paragraphKeys.includes(this.line.paragraphKey)
            ));
        }
    },
}